import { GECKO_CONSTS, GeckoLabel } from '@geckolabs/gecko-react-ui';
import { useRef } from 'react';
import './index.scss';

const KEYCODE = {
  DOWN: 40,
  LEFT: 37,
  RIGHT: 39,
  UP: 38,
};

const ButtonRadioButton = ({ id, option, iconOnly, isSelected, onClick }) => {
  const { name, icon, value, newOption } = option;
  const tabIndex = isSelected ? 0 : -1;
  return (
    <div
      role="radio"
      id={id}
      key={value}
      aria-checked={isSelected}
      tabIndex={tabIndex}
      value={value}
      onClick={onClick}
      aria-label={name}
    >
      {newOption && (
        <GeckoLabel
          name="New"
          type={GECKO_CONSTS.LABEL_TYPE_SUCCESS}
        />
      )}
      <i className={icon} />
      {iconOnly === false ? name : null}
    </div>
  );
};

const ButtonRadio = ({
  id,
  value,
  onChange,
  options,
  iconOnly = false,
  large,
}) => {
  const radioGroupRef = useRef(null);

  const focusOption = (option) => {
    const optionElement = radioGroupRef.current.querySelector(
      `[value="${option.value}"]`
    );
    optionElement.focus();
  };

  const selectOption = (option) => {
    onChange(option.value);
    focusOption(option);
  };

  const selectPreviousOption = () => {
    const currentIndex = options.findIndex((option) => option.value === value);
    if (currentIndex === 0) {
      const lastOption = options[options.length - 1];
      return selectOption(lastOption);
    }
    const previousOption = options[currentIndex - 1];
    return selectOption(previousOption);
  };

  const selectNextOption = () => {
    const currentIndex = options.findIndex((option) => option.value === value);
    if (currentIndex === options.length - 1) {
      const firstOption = options[0];
      return selectOption(firstOption);
    }
    const nextOption = options[currentIndex + 1];
    return selectOption(nextOption);
  };

  const onKeyDown = (event) => {
    switch (event.keyCode) {
      case KEYCODE.DOWN:
      case KEYCODE.RIGHT:
        event.preventDefault();
        event.stopPropagation();
        return selectNextOption();
      case KEYCODE.UP:
      case KEYCODE.LEFT:
        event.preventDefault();
        event.stopPropagation();
        return selectPreviousOption();
      default:
        return;
    }
  };

  return (
    <div
      id={id}
      className={`ButtonRadio${large ? ' ButtonRadioLg' : ''}`}
      role="radiogroup"
      onKeyDown={onKeyDown}
      ref={radioGroupRef}
    >
      {options.map((option, index) => {
        const isSelected = option.value === value;
        const onClick = () => selectOption(option);
        return (
          <ButtonRadioButton
            id={`${id}-option-${option.value}`}
            option={option}
            iconOnly={iconOnly}
            isSelected={isSelected}
            onClick={onClick}
            key={index}
          />
        );
      })}
    </div>
  );
};

export default ButtonRadio;
